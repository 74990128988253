var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"chat-container"}},[_c('header',{staticClass:"header"},[_c('img',{staticClass:"header-logo",attrs:{"src":_vm.duotalkLogo,"alt":"Duotalk Logo"}}),_vm._m(0)]),_c('div',{staticClass:"contact-info"},[_c('div',{staticClass:"profile-image"},[_c('span',[_vm._v(_vm._s(_vm.contactObject.name.charAt(0)))])]),_c('div',{staticClass:"contact-details"},[_c('h3',[_vm._v(_vm._s(_vm.contactObject.name))]),_c('p',[_vm._v(_vm._s(_vm.formattedPhoneNumber))]),_c('a',{attrs:{"href":`mailto:${_vm.contactObject.email}`}},[_vm._v(_vm._s(_vm.contactObject.email))]),_c('p',[_vm._v("ID: "+_vm._s(_vm.contactObject._id))])])]),_vm._l((_vm.formattedMessages),function(message,index){return _c('div',{key:index,staticClass:"message-wrapper"},[_c('div',{staticClass:"spacer"}),_c('div',{class:{
        message: true,
        user: message.author === 'me',
        bot: message.author === 'assistant' || message.author === 'system',
        log: message.author === 'log',
        'other-author':
          message.author !== 'me' &&
          message.author !== 'assistant' &&
          message.author !== 'system' &&
          message.author !== 'log' &&
          message.author !== 'sentimentAnalysis' &&
          message.author !== 'chatSummary',
      }},[(message.author === 'me' || _vm.isOperatorMessage(message))?_c('div',[_c('div',{staticClass:"message-info"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(message.authorName === "" ? _vm.contactObject.name : message.authorName)+":")])]),_c('div',{staticClass:"message-content"},[(message.data.mimetype === 'video/mp4')?_c('span',[_vm._v("Vídeo Recebido: "),_c('a',{staticClass:"file-link",attrs:{"href":`${_vm.API_URL}p/chat/redirFile/${message._id}/${message.data.filename}?acc=${message.acc}&token=${message.authToken}`,"target":"_blank"}},[_vm._v(_vm._s(message.data.file))])]):(
              message.data.mimetype === 'image/jpeg' ||
              message.data.mimetype === 'image/png'
            )?_c('span',[_vm._v("Imagem Recebida: "),_c('a',{staticClass:"file-link",attrs:{"href":`${_vm.API_URL}p/chat/redirFile/${message._id}/${message.data.filename}?acc=${message.acc}&token=${message.authToken}`,"target":"_blank"}},[_vm._v(_vm._s(message.data.file))])]):(message.data.mimetype === 'application/pdf')?_c('span',[_vm._v("Arquivo Recebido: "),_c('a',{staticClass:"file-link",attrs:{"href":`${_vm.API_URL}p/chat/redirFile/${message._id}/${message.data.filename}?acc=${message.acc}&token=${message.authToken}`,"target":"_blank"}},[_vm._v(_vm._s(message.data.file))])]):(message.data.mimetype === 'audio/ogg; codecs=opus')?_c('span',[_vm._v("Áudio Recebido: "),_c('a',{staticClass:"file-link",attrs:{"href":`${_vm.API_URL}p/chat/redirFile/${message._id}/${message.data.filename}?acc=${message.acc}&token=${message.authToken}`,"target":"_blank"}},[_vm._v(_vm._s(message.data.file))])]):_c('span',[_vm._v(" "+_vm._s(_vm.isOperatorMessage(message) ? _vm.extractMessageContent(message.data.text).message : message.data.text))]),_c('span',{staticClass:"timestamp"},[_vm._v(_vm._s(message.formattedTimestamp))])])]):(
          message.author === 'assistant' || message.author === 'system'
        )?_c('div',[_vm._m(1,true),(message.data.fileUrl)?_c('div',{staticClass:"message-content"},[_c('a',{staticClass:"file-link",attrs:{"href":message.data.fileUrl,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(message.data.fileUrl)+" ")])]):_vm._e(),_c('div',{staticClass:"message-content",class:{ long: message.isLong },domProps:{"innerHTML":_vm._s(message.formattedText)}}),(message.suggestions)?_c('div',[_c('div',{staticClass:"suggestions"},_vm._l((message.suggestions),function(suggestion,index){return _c('span',{key:index},[_vm._v(" - "+_vm._s(suggestion)),_c('br')])}),0)]):_vm._e(),_c('div',[_c('span',{staticClass:"timestamp"},[_vm._v(_vm._s(message.formattedTimestamp))])])]):(message.author === 'log')?_c('div',[_c('div',{staticClass:"message-content log-content"},[_c('p',[_vm._v(_vm._s(message.data.text))]),_c('p',{staticClass:"timestamp"},[_vm._v(_vm._s(message.formattedTimestamp))])])]):(
          message.author === 'chatSummary' ||
          message.author === 'sentimentAnalysis'
        )?_c('div',[_c('div',{staticClass:"gpt-content"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(message.author === "chatSummary" ? "Resumo da Conversa" : "Análise de Sentimento")+" ")]),_c('p',[_vm._v(_vm._s(message.data.text))]),_c('p',{staticClass:"timestamp"},[_vm._v(_vm._s(message.formattedTimestamp))])])]):_c('div',[_c('div',{staticClass:"message-info"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(message.authorName))])]),_c('div',{staticClass:"message-content",class:{ long: message.isLong },domProps:{"innerHTML":_vm._s(message.formattedText)}}),_c('div',[_c('span',{staticClass:"timestamp"},[_vm._v(_vm._s(message.formattedTimestamp))])])])])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-info"},[_c('h1',{staticClass:"company-name"},[_vm._v("Duotalk")]),_c('p',{staticClass:"company-details"},[_vm._v(" Plataforma de atendimento com Inteligência Artificial ")]),_c('p',{staticClass:"company-details"},[_c('a',{attrs:{"href":"https://www.duotalk.com.br","target":"_blank","rel":"noopener"}},[_vm._v("www.duotalk.com.br")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-info"},[_c('span',{staticClass:"name"},[_vm._v("Duotalk Bot")])])
}]

export { render, staticRenderFns }